<script lang="ts" setup>
defineProps({
  text: {
    type: String,
    default: "",
  },
  numberCharacters: {
    type: Number,
    default: 0,
    required: false,
  },
  numberWords: {
    type: Number,
    default: 0,
    required: false,
  },
});

const { numberWords: nWords } = useText();
</script>

<template>
  <div class="w-full flex justify-between">
    <TextsPopoverDownloadText :text="text" />
    <div class="flex items-center gap-4">
      <TextsTextInformation
        :number-characters="text ? text.length : numberCharacters"
        :number-words="text ? nWords(text) : numberWords"
      />
      <TextsCopyButton :text="text" />
      <TextsTextToSpeech :text="text" />
    </div>
  </div>
</template>

<style></style>
