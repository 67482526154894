<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    default: "es",
  },
});

onMounted(() => {
  if ("speechSynthesis" in window) {
    speechSynthesis.onvoiceschanged = () => {};
    onbeforeunload = () => {
      cancelSpeech();
    };
  }
});
onUnmounted(() => {
  cancelSpeech();
});

const speaking = ref(false);

const textToSpeech = () => {
  if (props.text === null || props.text === "") return false;
  if ("speechSynthesis" in window) {
    if (!speaking.value) {
      speakText();
    } else {
      cancelSpeech();
    }
  } else {
    // Speech Synthesis is not Supported 😞
  }
};

const speakText = () => {
  speaking.value = true;
  const speakData = new SpeechSynthesisUtterance(removeTags());
  speakData.onend = () => {
    speaking.value = false;
  };
  const voice = getVoice();
  if (voice) {
    speakData.voice = voice;
  }
  speechSynthesis.speak(speakData);
};

const getVoice = () => {
  const voices = speechSynthesis.getVoices();
  speechSynthesis.onvoiceschanged = () => {};
  const voice = voices.find((voice) => {
    // Split lang by - to get the language code
    const lang = voice.lang.split("-")[0];
    if (lang === props.language) {
      return voice;
    }

    return null;
  });

  return voice;
};

const cancelSpeech = () => {
  if ("speechSynthesis" in window) {
    speaking.value = false;
    speechSynthesis.cancel();
  }
};

function removeTags() {
  return props.text.toString().replace(/(<([^>]+)>)/gi, "");
}
</script>

<template>
  <UButton
    variant="soft"
    :color="speaking ? 'primary' : 'gray'"
    size="sm"
    :icon="speaking ? 'i-ph-speaker-simple-high' : 'i-ph-speaker-simple-slash'"
    :disabled="!text ? true : false"
    @click="textToSpeech"
  >
  </UButton>
</template>

<style></style>
